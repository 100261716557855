html {
  scroll-behavior: smooth;
}

.navigation a {
  color: #fff;
  padding-left: 20px;
}

/** Seite Partner **/
.bg-container {
  position: relative;
  padding: 30px 30px;
}

.white,
.white h2,
.white p {
  color: #fff;
}

.bg-partner-onlineverkaufen {
  background-color: #e84c3d;
}

.bg-partner-vorteile {
  li {
    display: grid;
    grid-template-columns: 100px 1fr;
    list-style-type: none;
    margin-bottom: 15px;
  }

  h3 {
    width: 100%;
  }
}

.bg-partner-vorteile ul.order-card {
  padding-left: 35px;
}

.inline-list-item {
  margin-top: 0;

  .fas {
    color: #e84c3d;
  }
}

h3.inline-list-item {
  padding-top: 15px;
}

.inline-list-item i {
  text-align: left;
}

.inline-list-item h3 {
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.navigation a {
  text-decoration: none;
}

.container h1 {
  color: #1b1b1b;
}

.bg-header-round-big, .bg-header-mobile-round-small {
  height: 100vh;
}

.bg-container h4 {
  text-align: left;
  margin: 10px 0;
}

.form-wrapper .form-field.full {
  width: 100%;
}

.form-wrapper .form-field {
  padding-right: 10px;
}

a.btn {
  padding: 10px;
  text-decoration: none;
}

.vertical-center {
  padding-top: 50px;
  text-align: right;
}

.eingerückt {
  padding-left: .5rem;
}

.formsubmit {
  position: absolute;
  top: 200px;
  right: 0px;
}

.formsubmit.success {
  background-color: #64bf7e;
  color: #fff;
  padding: 10px 20px;
}

.formsubmit.error {
  background-color: #e84c3d;
  color: #fff;
  padding: 10px 20px;
}

.headervideobtn {
  font-size: 6rem;
  height: 6rem;
  margin-top: 120px;
}

.modal-bg.video {
  position: fixed;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
  z-index: 9999999;
  display: none;
  align-items: center;
  justify-content: center;
}

.modal-close {
  position: absolute;
  right: 50px;
  top: 50px;
  color: #fff;
}

#cg-navbar {
  position: fixed;
}

#mobile_nav {
  display: none;
}

.header-partner-text {
  padding-left: 300px;
  padding-right: 300px;

  p {
    text-shadow: 0 0 15px rgba(0, 0, 0, 1);
  }
}

#header_video_iframe {
  box-shadow: #000 0px 0px 20px;
}

.bg-header-mobile-round-small {
  background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="70" viewBox="0 0 100 70" preserveAspectRatio="none"><path fill="%23F2EFED" d="M0 70 L0 0 C 40 65 60 65 100 0 L 100 70 Z"></path></svg>'), url(../img/couchgourmet-header-ultra-compressed-1180.jpg);
  background-size: 100% 70px, cover;
  background-position: bottom, bottom;
  background-repeat: no-repeat, no-repeat;
  box-shadow: inset 0 70px 50px -44px #000;
  -moz-box-shadow: inset 0 70px 50px -44px #000;
  -webkit-box-shadow: inset 0 70px 50px -44px #000;
  margin-bottom: 55px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .container {
    height: 934px;
    position: relative;
    padding-top: 175px;
    text-align: center;

    p.font-extralarge {
      text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    }

    button {
      margin-top: 20px;
      font-weight: 700;
      margin-bottom: 131px;
    }
  }
}

@media only screen and (max-width: 400px) {
  #header_video_iframe {
    width: 290px !important;
    height: 163px !important;
  }
}

@media only screen and (min-width: 401px) and (max-width: 760px) {
  #header_video_iframe {
    width: 400px !important;
    height: 225px !important;
  }
}

@media only screen and (max-width: 712px) {
  #mobile_nav {
    position: fixed;
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    grid-auto-columns: 1fr;

    button {
      color: #fff;
      font-size: 2rem;
      text-align: right;
      padding-right: 10px;
      padding-top: 10px;
    }
  }
  #mobile_links {
    display: none;

    a {
      padding: 10px 10px;
      text-decoration: none;
      color: #fff;
      text-align: right;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  #desktop_nav.navigation {
    display: none;
  }
  .bg-header-round-big .container {
    height: 720px;
    padding-top: 290px;
  }
  #cg-navbar {
    position: absolute;
  }
  .bg-header-mobile-round-small {
    background-size: 100% 40px, cover;
    background-position: bottom, 100% 100%;
    height: 387px;

    .container {
      padding-top: 125px;

      h1 {
        font-size: 2.5rem;
        font-weight: 400;
        line-height: 1;
      }

      button, .headerbuttons {
        display: none;
      }
    }
  }
  .header-partner-text p {
    display: none;
  }
  .partner-text-sm {
    display: block;
  }
}

@media only screen and (min-width: 711px) and (max-width: 1300px) {
  #cg-navbar {
    display: block;
    position: absolute;
  }
  #desktop_nav.navigation {
    margin-top: 95px;
    display: flex;
    justify-content: center;
  }
  .header-partner-text {
    padding-left: 120px;
    padding-right: 120px;
  }
}

.bg-header-round-small .container {
  padding-top: 125px;
}

#contact {
  padding-top: 90px;
}


.bg-schiefertafel {
  background: url(../img/schiefertafel.jpg) no-repeat;
  background-color: #131313;
  background-size: cover;

  p {
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  }
}


.honeypot {
  display: none;
}

.partner-pdf {
  color: #000;
}

textarea {
  font-size: 1.07rem
}

.contact-image {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3)
}
